import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import PageLayout from '../../../components/layouts/longform.js';
import Helmet from 'react-helmet';
import Seo from '../../../components/seo';
export const _frontmatter = {
  "date": "2022-06-07T21:40+01:00"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="SwiftUI @ WWDC 2022" keywords={["WWDC", "SwiftUI", "Resources", "What's new"]} description="A collection of links to various SwiftUI-related announcements, resources, and tips from Apple and the community during the week of WWDC 2022." image="social-cards/default.jpg" largeImage={true} mdxType="Seo" />
    <Helmet bodyAttributes={{
      class: 'collection'
    }} mdxType="Helmet" />
    <h1 {...{
      "id": "swiftui--wwdc-2022",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#swiftui--wwdc-2022",
        "aria-label": "swiftui  wwdc 2022 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SwiftUI @ WWDC 2022`}</h1>
    <p>{`This is a collection of links to various SwiftUI-related announcements, resources, and tips from Apple and the community during the week of WWDC 2022 and beyond.`}</p>
    <h2 {...{
      "id": "apis-diffs--release-notes-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#apis-diffs--release-notes-",
        "aria-label": "apis diffs  release notes  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`APIs Diffs & Release Notes 🔬`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/ios-ipados-release-notes/ios-ipados-16-release-notes#SwiftUI"
        }}>{`iOS & iPadOS 16 Beta 1 Release Notes`}</a>{` by Apple`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/swiftui?changes=latest_minor"
        }}>{`Official API diff`}</a>{` by Apple`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/swiftui/view-deprecated"
        }}>{`Deprecated Modifiers`}</a>{` by Apple`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swiftui-lab.com/swiftui-22-in-numbers/"
        }}>{`SwiftUI ’22 in Numbers`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/swiftuilab"
        }}>{`@SwiftUILab`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twostraws/swiftui-changelog#xcode-14"
        }}>{`SwiftUI Changelog`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/twostraws"
        }}>{`@twostraws`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mackuba.eu/swiftui/changelog"
        }}>{`SwiftUI Index`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/kuba_suder"
        }}>{`@kuba_suder`}</a></li>
    </ul>
    <h2 {...{
      "id": "sample-code-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sample-code-",
        "aria-label": "sample code  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sample Code 🧪`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/swiftui/bringing_robust_navigation_structure_to_your_swiftui_app"
        }}>{`Bringing robust navigation structure to your SwiftUI app`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/swiftui/food_truck_building_a_swiftui_multiplatform_app"
        }}>{`Food Truck: Building a SwiftUI multiplatform app`}</a>{` / `}<a parentName="li" {...{
          "href": "https://github.com/apple/sample-food-truck"
        }}>{`GitHub Repository`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/swiftui/bringing_multiple_windows_to_your_swiftui_app"
        }}>{`Bringing multiple windows to your SwiftUI app`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/swiftui/composing_custom_layouts_with_swiftui"
        }}>{`Composing custom layouts with SwiftUI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/uikit/views_and_controls/using_swiftui_with_uikit"
        }}>{`Using SwiftUI with UIKit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/weatherkit/fetching_weather_forecasts_with_weatherkit"
        }}>{`Fetching weather forecasts with WeatherKit`}</a></li>
    </ul>
    <h2 {...{
      "id": "wwdc-sessions-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#wwdc-sessions-",
        "aria-label": "wwdc sessions  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WWDC Sessions 📺`}</h2>
    <h3 {...{
      "id": "tuesday",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tuesday",
        "aria-label": "tuesday permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tuesday`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10052"
        }}>{`[10052] What’s new in SwiftUI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10054"
        }}>{`[10054] The SwiftUI cookbook for navigation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10136"
        }}>{`[10136] Hello Swift Charts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10050"
        }}>{`[10050] Complications and widgets: Reloaded`}</a></li>
    </ul>
    <h3 {...{
      "id": "wednesday",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wednesday",
        "aria-label": "wednesday permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wednesday`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10133"
        }}>{`[10133] Build a productivity app for Apple Watch `}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10056"
        }}>{`[10056] Compose custom layouts with SwiftUI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10062"
        }}>{`[10062] Meet Transferable`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10137"
        }}>{`[10137] Swift Charts: Raise the bar`}</a></li>
    </ul>
    <h3 {...{
      "id": "thursday",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#thursday",
        "aria-label": "thursday permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thursday`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10051"
        }}>{`[10051] Go further with Complications in WidgetKit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/110343"
        }}>{`[110343] SwiftUI on iPad: Add toolbars, titles, and more`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10058"
        }}>{`[10058] SwiftUI on iPad: Organize your interface`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10059"
        }}>{`[10059] The craft of SwiftUI API design: Progressive disclosure`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10075"
        }}>{`[10075] Use SwiftUI with AppKit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10072"
        }}>{`[10072] Use SwiftUI with UIKit`}</a></li>
    </ul>
    <h3 {...{
      "id": "friday",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#friday",
        "aria-label": "friday permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Friday`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10061"
        }}>{`[10061] Bring multiple windows to your SwiftUI app`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/wwdc22/10142"
        }}>{`[10142] Efficiency awaits: Background tasks in SwiftUI`}</a></li>
    </ul>
    <h2 {...{
      "id": "topics-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#topics-",
        "aria-label": "topics  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Topics 📚`}</h2>
    <h3 {...{
      "id": "whats-new",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#whats-new",
        "aria-label": "whats new permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What’s New`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swiftwithmajid.com/2022/06/07/what-is-new-in-swiftui-after-wwdc22/"
        }}>{`What is new in SwiftUI after WWDC22`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/mecid"
        }}>{`@mecid`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.hackingwithswift.com/articles/250/whats-new-in-swiftui-for-ios-16"
        }}>{`What’s new in SwiftUI for iOS 16`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/twostraws"
        }}>{`@twostraws`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bigmountainstudio.github.io/What-is-new-in-SwiftUI/"
        }}>{`What’s New In SwiftUI for iOS Cheat Sheet`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/bigmtnstudio"
        }}>{`@BigMtnStudio`}</a></li>
    </ul>
    <h3 {...{
      "id": "navigation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#navigation",
        "aria-label": "navigation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Navigation`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/swiftui/migrating-to-new-navigation-types/"
        }}>{`Migrating to New Navigation Types`}</a>{` by Apple`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nilcoalescing.com/blog/SwiftUINavigation2022/"
        }}>{`Overview of the New SwiftUI Navigation APIs`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/natpanferova"
        }}>{`@natpanferova`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nilcoalescing.com/blog/ProgrammaticallyHideAndShowSidebarInSplitView/"
        }}>{`Programmatically Hide and Show Sidebar in Split View`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/natpanferova"
        }}>{`@natpanferova`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cindori.com/developer/hands-on-swiftui-navigation-stack"
        }}>{`Hands-on with NavigationStack in SwiftUI`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/oskargroth"
        }}>{`@oskargroth`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swiftwithmajid.com/2022/06/15/mastering-navigationstack-in-swiftui-navigator-pattern/"
        }}>{`Mastering NavigationStack in SwiftUI. Navigator Pattern.`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/mecid"
        }}>{`@mecid`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=3Ur6kUStKRo"
        }}>{`📺 First Look at the Navigation API in SwiftUI`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/azamsharp"
        }}>{`@azamsharp`}</a></li>
    </ul>
    <h3 {...{
      "id": "swift-charts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#swift-charts",
        "aria-label": "swift charts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Swift Charts`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nilcoalescing.com/blog/BuildAndStyleAChartWithSwiftChartsFramework/"
        }}>{`Build and Style a Chart with the New Swift Charts Framework`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/hishnash"
        }}>{`@hishnash`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nilcoalescing.com/blog/RidgePlotWithSwiftCharts/"
        }}>{`Ridgeline plot with Swift Charts`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/hishnash"
        }}>{`@hishnash`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jordibruin/Swift-Charts-Examples"
        }}>{`🧪 Swift Charts Examples`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/jordibruin"
        }}>{`@jordibruin`}</a></li>
    </ul>
    <h3 {...{
      "id": "layout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#layout",
        "aria-label": "layout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Layout`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://talk.objc.io/episodes/S01E308-the-layout-protocol"
        }}>{`📺 The Layout Protocol`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/objcio"
        }}>{`@objcio`}</a></li>
    </ul>
    <h3 {...{
      "id": "macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#macos",
        "aria-label": "macos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`macOS`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cindori.com/developer/hands-on-menu-bar"
        }}>{`Hands-on: building a Menu Bar experience with SwiftUI`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/joogps"
        }}>{`@joogps`}</a></li>
    </ul>
    <h3 {...{
      "id": "uikit--appkit-interop",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#uikit--appkit-interop",
        "aria-label": "uikit  appkit interop permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`UIKit / AppKit Interop`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.swiftbysundell.com/articles/rendering-swiftui-views-within-uitableview-or-uicollectionview/"
        }}>{`Rendering SwiftUI views within UITableView or UICollectionView cells on iOS 16`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/johnsundell"
        }}>{`@johnsundell`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swapnanildhol.blog/blog/tech/2022-06-11-iOS-swiftui-with-uikit-22"
        }}>{`Using SwiftUI with UIKit in 2022`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/swapnanildhol"
        }}>{`@swapnanildhol`}</a></li>
    </ul>
    <h3 {...{
      "id": "misc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#misc",
        "aria-label": "misc permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Misc`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://danielsaidi.com/blog/2022/06/20/using-the-swiftui-imagerenderer"
        }}>{`Using the SwiftUI 4 ImageRenderer`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/danielsaidi"
        }}>{`@DanielSaidi`}</a>{` `}</li>
    </ul>
    <h2 {...{
      "id": "qa-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#qa-",
        "aria-label": "qa  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Q&A 💬`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://midnight-beanie-ccb.notion.site/swiftui-lounge-wwdc22-e20094b91f074398ba395c3fa245e63d"
        }}>{`Every question and answer from WWDC 22’s SwiftUI digital lounge!`}</a>{` by `}<a parentName="li" {...{
          "href": "http://twitter.com/mtrostyle"
        }}>{`@mtrostyle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swiftui-lab.com/digital-lounges-2022/"
        }}>{`WWDC 2022: Lessons from the SwiftUI Digital Lounges`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/swiftuilab"
        }}>{`@SwiftUILab`}</a></li>
    </ul>
    <p>{`Want to add a resource? Feel free to `}<a parentName="p" {...{
        "href": "https://github.com/kaishin/redalemeden.com/edit/main/src/pages/collections/swiftui-2022/index.mdx"
      }}>{`open a PR!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      