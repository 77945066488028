import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link, graphql } from 'gatsby';
import ProjectList from '../components/project/project-list.js';
import ArticleList from '../components/article-list.js';
import Seo from '../components/seo';
import Helmet from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import AppleLogo from '../images/apple-logo.svg';
import SwiftLogo from '../images/swift-logo.svg';
import DefaultLayout from '../components/layouts/default.js';
export const pageQuery = graphql`
  query Index {
    site {
      siteMetadata {
        title
        authorBio
      }
    }
    currentProjects: allProjectsYaml(limit: 6, filter: {state: {in: ["current"]}}, sort: { fields: [year], order: DESC }) {
      list: edges {
        project: node {
          ...ProjectDetails
        }
      }
    }
    recentProjects: allProjectsYaml(limit: 6, filter: {state: {in: ["recent"]}}, sort: { fields: [year], order: DESC }) {
      list: edges {
        project: node {
          ...ProjectDetails
        }
      }
    }
    highlightedArticles: allArticlesYaml(
      limit: 3
      filter: { highlighted: { eq: true } }
      sort: { fields: [published], order: DESC }
    ) {
      list: edges {
        article: node {
          title
          url
          outlet_name
          formattedDate: published(formatString: "MMMM DD, YYYY")
        }
      }
    }
    recentArticles: allArticlesYaml(limit: 3, sort: { fields: [published], order: DESC }) {
      list: edges {
        article: node {
          title
          url
          outlet_name
          formattedDate: published(formatString: "MMMM DD, YYYY")
        }
      }
    }
    recentPodcasts: allPodcastsYaml(limit: 3, sort: { fields: [published], order: DESC }) {
      list: edges {
        podcast: node {
          name
          thumbnail {
            name
            extension
            childImageSharp {
              gatsbyImageData(width: 256, layout: CONSTRAINED)
            }
          }
          url
          description
          episode
          role
          published
          formattedPublishedDate: published(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="Reda Lemeden" mdxType="Seo" />
    <Helmet bodyAttributes={{
      class: 'home-page'
    }} mdxType="Helmet" />
    <ReactTooltip type="light" textColor="var(--theme-foreground-color)" backgroundColor="var(--theme-body-background-color)" mdxType="ReactTooltip" />
    <div data-tip="Hi, my name is Reda Lemeden." title="Hi, my name is Reda Lemeden.">
      <h3 {...{
        "id": "こんにちは-mitt-name-est-reda-لمدن",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E3%81%93%E3%82%93%E3%81%AB%E3%81%A1%E3%81%AF-mitt-name-est-reda-%D9%84%D9%85%D8%AF%D9%86",
          "aria-label": "こんにちは mitt name est reda لمدن permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`こんにちは, `}<em parentName="h3">{`mitt`}</em>{` name `}<em parentName="h3">{`est`}</em>{` Reda لمدن`}</h3>
    </div>
    <div className="intro-content">
      <h1 {...{
        "id": "im-a-software-developer-designer-and-tech-writer-based-in-stockholm",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#im-a-software-developer-designer-and-tech-writer-based-in-stockholm",
          "aria-label": "im a software developer designer and tech writer based in stockholm permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`I’m a software developer, designer, and tech writer based in Stockholm.`}</h1>
    </div>
    <p>{`I spent two thirds of my life in my hometown of Casablanca `}<span title="Morocco">{`🇲🇦`}</span>{`,
where I grew up playing `}<span title="Atari, DOS, Nintendo, etc.">{`video games 🎮`}</span>{` and
tinkering with `}<span title="Macromedia, Adobe, etc.">{`creative software 🖌`}</span>{`.`}</p>
    <p>{`After getting a `}<abbr title="Bachelor of Business Administration">{`BBA`}</abbr>{` degree in 2007,
I moved to Tokyo `}<span title="Japan">{`🇯🇵`}</span>{` to study Japanese,
then Nantes `}<span title="France">{`🇫🇷`}</span>{` to resume my post-graduate studies (`}<abbr title="Master in Management">{`MIM`}</abbr>{`),
then Boston, MA `}<span title="USA">{`🇺🇸`}</span>{` to work as a product designer & developer,
before finally settling in Stockholm `}<span title="Sweden">{`🇸🇪`}</span>{` in 2014. `}</p>
    <p>{`Today I write software—and about software—for a living.
I particularly enjoy working with `}<strong parentName="p">{`interaction`}</strong>{` and `}<strong parentName="p">{`interface`}</strong>{` design,
`}<strong parentName="p">{`web`}</strong>{` technologies, `}<strong parentName="p">{`app development`}</strong>{` on Apple platforms, and more recently `}<strong parentName="p">{`server-side`}</strong>{` Swift.`}</p>
    <section className="home-content">
      <h2 {...{
        "id": "currently-working-on",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#currently-working-on",
          "aria-label": "currently working on permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Currently Working On`}</h2>
      <ProjectList projects={props.data.currentProjects.list} mdxType="ProjectList" />
      <h2 {...{
        "id": "recent-projects",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#recent-projects",
          "aria-label": "recent projects permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Recent Projects`}</h2>
      <ProjectList projects={props.data.recentProjects.list} mdxType="ProjectList" />
    </section>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      