import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import PageLayout from '../../components/layouts/longform.js';
import Helmet from 'react-helmet';
import Seo from '../../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="Words I Frequently Misspell" keywords={["English", "Typos", "Language"]} description="Here is a list of English words I often get wrong—in spelling, pronunciation, or both." mdxType="Seo" />
    <Helmet bodyAttributes={{
      class: 'frequent-typos'
    }} mdxType="Helmet" />
    <h1 {...{
      "id": "words-i-frequently-mispell-misspell",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#words-i-frequently-mispell-misspell",
        "aria-label": "words i frequently mispell misspell permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Words I Frequently `}<del parentName="h1">{`Mispell`}</del>{` Misspell`}</h1>
    <hr></hr>
    <p>{` Here is a list of English words I often get wrong—in spelling, pronunciation, or both:`}</p>
    <ul>
      <li parentName="ul"><del parentName="li">{`Dissmiss`}</del>{` `}<strong parentName="li">{`Dismiss`}</strong>{`.`}</li>
      <li parentName="ul"><del parentName="li">{`Adress`}</del>{` `}<strong parentName="li">{`Address`}</strong>{`. From the French spelling of “adresse”.`}</li>
      <li parentName="ul"><del parentName="li">{`Substract`}</del>{` `}<strong parentName="li">{`Subtract`}</strong>{`. From the French “soustraire”.`}</li>
      <li parentName="ul"><del parentName="li">{`Appartment`}</del>{` `}<strong parentName="li">{`Apartment`}</strong>{`. You guessed it, from the french “appartement”.`}</li>
      <li parentName="ul"><del parentName="li">{`Negligeable`}</del>{` `}<strong parentName="li">{`Negligible`}</strong>{`. Yup, in French it’s “Négligeable”.`}</li>
      <li parentName="ul"><del parentName="li">{`Weary`}</del>{` `}<strong parentName="li">{`Wary`}</strong>{` (as in cautious).`}</li>
      <li parentName="ul"><del parentName="li">{`Rethoric`}</del>{` `}<strong parentName="li">{`Rhetoric`}</strong>{`.`}</li>
      <li parentName="ul"><del parentName="li">{`Occurance`}</del>{` `}<strong parentName="li">{`Occurence`}</strong>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      