import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import PageLayout from '../../../components/layouts/longform.js';
import Helmet from 'react-helmet';
import Seo from '../../../components/seo';
export const _frontmatter = {
  "date": "2022-09-05T18:40+01:00"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="Stable Diffusion" keywords={["Stable", "Diffusion", "Machine", "Learning", "AI", "ML"]} description="A collection of links to various Stable Diffusion-related announcements, resources, and tips from Stability Ai and the community." image="social-cards/default.jpg" largeImage={true} mdxType="Seo" />
    <Helmet bodyAttributes={{
      class: 'collection'
    }} mdxType="Helmet" />
    <h1 {...{
      "id": "stable-diffusion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#stable-diffusion",
        "aria-label": "stable diffusion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stable Diffusion`}</h1>
    <p>{`This is a collection of links to various Stable Diffusion-related announcements, resources, and tips from Stability Ai and the community.`}</p>
    <h2 {...{
      "id": "announcements-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#announcements-",
        "aria-label": "announcements  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Announcements 📢`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stability.ai/blog/stable-diffusion-announcement"
        }}>{`Stable Diffusion Launch Announcement`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stability.ai/blog/stable-diffusion-public-release"
        }}>{`Stable Diffusion Public Release`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://thealgorithmicbridge.substack.com/p/stable-diffusion-is-the-most-important"
        }}>{`Stable Diffusion Is the Most Important AI Art Model Ever`}</a></li>
    </ul>
    <h2 {...{
      "id": "source-code-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#source-code-",
        "aria-label": "source code  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Source Code 🔬`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/CompVis/stable-diffusion"
        }}>{`Stable Diffusion`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Stability-AI/stability-sdk"
        }}>{`Stability SDK`}</a>{`. Comes with protobuf support.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jquesnelle/txt2imghd"
        }}>{`txt2imghd`}</a>{`: Generate large resolution images using SD.`}</li>
    </ul>
    <h2 {...{
      "id": "papers-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#papers-",
        "aria-label": "papers  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Papers 📄`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2112.10752"
        }}>{`High-Resolution Image Synthesis with Latent Diffusion Models`}</a>{`. Note: Stable Diffusion is based on Latent Diffusion.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2208.11970"
        }}>{`Understanding Diffusion Models: A Unified Perspective`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2208.01618"
        }}>{`An Image is Worth One Word: Personalizing Text-to-Image Generation using Textual Inversion`}</a></li>
    </ul>
    <h2 {...{
      "id": "videos-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#videos-",
        "aria-label": "videos  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Videos 📺`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=SdahlzTVqVc"
        }}>{`Text-to-Image Generation – Deep Dive into DALL-E 2, Imagen, Parti, VQGAN with Jerry Chi`}</a></li>
    </ul>
    <h2 {...{
      "id": "google-collab-notebooks-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#google-collab-notebooks-",
        "aria-label": "google collab notebooks  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Google Collab Notebooks 🧪`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://colab.research.google.com/github/huggingface/notebooks/blob/main/diffusers/diffusers_intro.ipynb"
        }}>{`Getting Started with Diffusers Collab Notebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://colab.research.google.com/drive/1cJPmCCUFqVMaF--ee51RVFDCOF09Epbc"
        }}>{`Stable Diffusion Lite Collab Notebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://brainartlabs.com/2022/08/28/stablediffusion-notebook-by-pharmapsychotic-setup-tutorial/"
        }}>{`StableDiffusion Notebook Setup Tutorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://colab.research.google.com/drive/1dlgggNa5Mz8sEAGU0wFCHhGLFooW_pf1"
        }}>{`Grokking Stable Diffusion`}</a></li>
    </ul>
    <h2 {...{
      "id": "other-demos-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other-demos-",
        "aria-label": "other demos  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other Demos 🎠`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://huggingface.co/spaces/huggingface/diffuse-the-rest"
        }}>{`Diffuse the Rest`}</a>{`. Draw a sketch and diffuse the rest.`}</li>
    </ul>
    <h2 {...{
      "id": "gui--cli-️",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#gui--cli-%EF%B8%8F",
        "aria-label": "gui  cli ️ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GUI & CLI 🖱️`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/AUTOMATIC1111/stable-diffusion-webui"
        }}>{`AUTOMATIC1111 Web UI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/lstein/stable-diffusion"
        }}>{`lstein REPL & Web UI`}</a></li>
    </ul>
    <h2 {...{
      "id": "prompts--showcases-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prompts--showcases-",
        "aria-label": "prompts  showcases  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prompts & Showcases 🎨`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://lexica.art"
        }}>{`Lexica.art`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reddit.com/r/StableDiffusion/comments/xcrm4d/useful_prompt_engineering_tools_and_resources/"
        }}>{`Useful Prompt Engineering tools and resources`}</a></li>
    </ul>
    <h2 {...{
      "id": "topics-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#topics-",
        "aria-label": "topics  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Topics 📚`}</h2>
    <h3 {...{
      "id": "diffusers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#diffusers",
        "aria-label": "diffusers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Diffusers`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://huggingface.co/blog/annotated-diffusion"
        }}>{`The Annotated Diffusion Model`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://huggingface.co/blog/stable_diffusion"
        }}>{`Stable Diffusion with 🧨 Diffusers`}</a></li>
    </ul>
    <h2 {...{
      "id": "other-collections-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other-collections-",
        "aria-label": "other collections  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other Collections 📁`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Maks-s/sd-akashic"
        }}>{`Akashic Records`}</a></li>
    </ul>
    <p>{`Want to add a resource? Feel free to `}<a parentName="p" {...{
        "href": "https://github.com/kaishin/redalemeden.com/edit/main/src/pages/collections/stable-diffusion/index.mdx"
      }}>{`open a PR!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      