import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from '../../components/layouts/default';
import Seo from '../../components/seo';
import Helmet from 'react-helmet';
import PageHeader from '../../components/page-header';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Seo title="Portfolio" mdxType="Seo" />
    <Helmet bodyAttributes={{
      class: 'portfolio-page'
    }} mdxType="Helmet" />
    <PageHeader title="Portfolio" mdxType="PageHeader">
      <p>{`  A showcase of some of the design work I have done through the years. Please `}<a parentName="p" {...{
          "href": "/contact"
        }}>{`get in touch`}</a>{` for further inquiries.`}</p>
    </PageHeader>
    <h3 {...{
      "id": "2020",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2020",
        "aria-label": "2020 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2020`}</h3>
    <p>{`In prepration for macOS Big Sur, I had the pleasure to work with Kapeli on a new icon for their
beloved `}<a parentName="p" {...{
        "href": "https://kapeli.com/dash"
      }}>{`documentation viewing`}</a>{` for Mac.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e81fb9842c1535cac8b3b279edb05789/7842b/dash.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADmUlEQVQozxXLfVCTdRwA8Oeuu/QfkmJ3nYqDXq48CvW6lWAaXNcfpnZAmRdoWnblGalJXKTFFePOq87w6shA7EBiOBA2tiFzuGcvbHs2tj3PXt0rbUzcYGywh7HRBs/z/XXeff79YKmXP+c3Dj0nim+VJIpHYsVD0RLhI+7g3A5BhCuY5QoiJQOzJf3h4luh7b3/buvxb+90b71KFbSbCtqMGNpVh2ovhlVOtWdxNJwdDmWGA2lhIC0M0sJgWjiTvR3KCYOZIc/ykHd52Jsa9qUG3cnqPs+TbSaMPVDLdPYiq2NNT0YCMUc8b15aN85nDKG0+UHEaTL78Hsk6VI9SssDybu+pMyX1EXoy/jDzVfMGByqga4eIG1Ia2AMZM4bjE8S1isxS/Mcdd5rb3S6v7TazzktQpqMZnUhWuFLqoJL38oCmy9rMKg5DDdvgMMGBj1LWFjZX+YzUslJeuKLBdlnCcmnyZH6pPh4QtoQC1K5hTz44znPwhpfMbOpWYHB0YPQ2wUOGzIa18gpt/STsQai78JiKrGezwKzBh75av+pue66iFuRzKP8cnYj9R9rX4A/jBkMjr0Nt66Di0LThhyl04iO93ykOrc/2HvtoVq/cmcwtorQ+I2l1qqwc8yOVi25Rfc6HaaTodlEAoMPqqC/E1wUa9EhG6WXtvxYK61/zdvWPGP053nbcJzKjolSp3l+yz9GFJ1gwuMwrwHLD4yAi0EpDzqugZ1kpnXITjmVXWeOSA6Vu75vCRpjcPVmXB+FS00z7+1x6q6bkBtnPHKIqsH1DRJjGGDl0PQL6KZZpQaZiBWD8liN8s1XrCdOOgYIesCQ+qrlwVt7iCqeXd5BIFK9QeDgIeA+n+1+FgOsGr7+He6bQaxmx7VIM3W2nih+wVz2qu7FnZOlzyu4O5Q7y4wvlVmIHg0yqxktDjYT/N0BH/IwwBrgfC9M2mGUYEQGdFfv7NK8fxCv2Ie/sffe63vllfuV+w7gPzcpQasCrRq0OFiN0P0rHN6FMVgjXBwBjR/EFEhtICGR3LwuM9CiKVqmpeUaWqHJ4Gpk0iL9FOi0jz9lhT9/g+pyLPnEdysVnfk+y8YgyQisIDCzA9MgMCGBHgm06LYa3VEi0SQ7KgfRxGOSCRBL4dTHULkbcxe0+Ta1Bgv5YU77XFH7fBF/ifNTmtOa41zaeLqZKbzAbjkLT52GLfVQWAeFR+CZd6HoHeBUQGnl/21AcRcnQGGHAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dash icon design (macOS).",
              "title": "Dash icon design (macOS).",
              "src": "/static/e81fb9842c1535cac8b3b279edb05789/7842b/dash.png",
              "srcSet": ["/static/e81fb9842c1535cac8b3b279edb05789/f2d49/dash.png 300w", "/static/e81fb9842c1535cac8b3b279edb05789/ff59c/dash.png 600w", "/static/e81fb9842c1535cac8b3b279edb05789/7842b/dash.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Dash icon design (macOS).`}</figcaption>{`
  `}</figure></p>
    <p>{`During the last 3 years, I worked on Kaching’s app protfolio for the retail industry in Sweden and abroad. The role involved
designing hundreds of screens and micro-intreactions, then evolved to leading the design and product teams to deliver, test,
and iterate on the product.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9b4bf693e0ff362fe02d6036c4f1140b/0d4f8/kaching.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEGElEQVQ4yzWOfUzUBRzG76+mBjq8u9/vjvsdeNzgx+shdwecetz9uIOtfCtMyha2MitShDAU1F7UmqUFa9pyQ7ayOVPBWDMlzyjUAF/gFFhKhcqLaOnwJTlNDu/TONezPd999zzf57tH5TW24jV0oyR8wsdrrWypcLFi5VKcyVVkR/1EY0MzYw9DjAdDNNY3M1vzC7LwGlKshkQ5DSlGR7r4IfnGbjxSKyqvsR1F30G8dj5ux3Sc9nicOXbsMZtw6prxNR1n7F+YGL6mn3HpW0jWVGIyS6QkW5GTTMj6F/EaOvEY21B5pNN4Ylp4/ZVKSsrKWVW6hvkL5mFRV+OSjnCq/SyPQiEIBjjd1o5LaiJdXU2cOY60VBsWSzrxsbPI0R8jz3gGlVfyMyfmayqrqih+s5iNH2zi2WcKsan34Iqtx9/ZRWD0AWPBED3dF8JalvAtgigimfSYZRNGkw6buJP8mPMTD89hkYqxZsQhCFqmRU0iImIac0QfTlMtQ8P9BB+NM/5onOHrgzhNdcwSD2MSrOTLbzHXUkauXIRF/zZ5Rv/jhqbIYgzqmQiRmWimphGveZlcwymc0gHKi7dTUfKYE/uEphjaSNGuIE39EkmRRcRHFmCOXIXH0IHKZTxE0cKPWPbCRpY9v5lXCytxyzV4o7vJ0teiedKOZkom0yfbEKc6yI6uwy10sti1l+pt31NaUseGqt3U1rTjNR1FVajs4vbNPnq7T3Gpp4ORoR6edqxDEX4jUViOWvcEDkc2VpsVrX4SieIbuDQ9LM7fzEDvVwx1fcnd6yfpPN9OgXMnqo2rvyH0MMjAvm0MHv6OXr8fe+x68qIvkCAsRZqhJSk5jcTEFPTGKBLEIjy6C+TI7+GrL+VkQzG9p3fj8/1I2fIaVAcP/MBg/zBXLw1w79YorSfayBC34o3uQtYvwTrTijXNSWpCFrIskxC9JOxl6LZx5qyfu6MwcnuMCbS3+VENDAxz7dpN/r5xh8D9IPsPNJKh3oVHOkO8UMBTprUskj9lUeJnzDVXkSA+F/Zs2jq2bNnKxYsX6evr52B9I3samlAF7gcYDdxjZGSEEON8XlOLJWIvuYZWUs3z8XrduHO8KK48FMVJsmkeSvSv2KLqWb6sBP+5Di5fusLRo8c4cqwFVeDOOA/+CXHvVjBce8OaHVimNKBILcgzFDKzrCiKh9xcL/bMDEz62eQaj2OZ3MC7a78IZ+7euc//UO1fP8T+9y+zb91VDlVfoXDuehzTm3EZfIiaOARdJAZJCFMrRiCq43AbfGRHNVM4bx1D/V38+cfv9A/28deNq6jc6eXMSlqJO3017oxS7PodeKWzKIYTpIrvYBYWYtIUhBmnXUCKuDrsTdzY9dvxZJWR76ggZ+ZKvFnl/Ac5ZLQ88ANGGwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Kaching app for iOS (Interface Design, Product Leadership).",
              "title": "Kaching app for iOS (Interface Design, Product Leadership).",
              "src": "/static/9b4bf693e0ff362fe02d6036c4f1140b/5ece7/kaching.png",
              "srcSet": ["/static/9b4bf693e0ff362fe02d6036c4f1140b/f2d49/kaching.png 300w", "/static/9b4bf693e0ff362fe02d6036c4f1140b/ff59c/kaching.png 600w", "/static/9b4bf693e0ff362fe02d6036c4f1140b/5ece7/kaching.png 1200w", "/static/9b4bf693e0ff362fe02d6036c4f1140b/0d4f8/kaching.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Kaching app for iOS (Interface Design, Product Leadership).`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "2017",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2017",
        "aria-label": "2017 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2017`}</h3>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f7ba75f9f7b190960ac81936dcd07465/0d4f8/speedster-icon.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD5ElEQVQ4y1WUe0+TZxjG34/gsg0mcnjfctAtIkqHQ4QNSltgcUNtnMAMDmVDEZDhGKiNnNqKbWkFkYMnCgVafGuhB9pCYBo0Jsu2ZHMO0W3ZV9g3+C3vO//Y/rjyXHeePL/c9/0kl7BbdpMxegnpVg/inV6kqX5EjwXJa0MzP0jarI2UeRsp/kHSZAeZoWHE0DCpS24yYzfJio+RszJBdmKcfU+8CKW/hMiaGkC8dQVJOT0DaLw2UmcsJM9ZyYuNoVv3UPZwmt3rt0kKuUiW7WyX7YjRGypM0Xvrdyh7HkEwvlrhgw0vGbd7kDwWNDNWtnv62RsaoW8jxowvTGDET3TYz7IvgvXpClnLN9g2b2VHwKnCdq5OUvRTAMNWHKF8cxnDywS54VHEe/3smB6gMDLGxHKCS812mk53cb79Mub2y7hPdZM45yQcX2V3bIxtcxbSFl1on86hfxFDYQn6zRj6rRhlz0Jkzl9D8g/iji9zorYbY1snLebr9Lfc5Oq5UfrN1/G2mjHXXMSWiJL8wM5bPhsHfpQxvkz8C1Tm1m8uU/FqhdyNaY6veunqdFL8xVkavnZwurqPox+fo7W6g81TLqZaLeTXn6G708nhNS9vyIPsWplE91tU7VLIfXhPnd2wGaPoRZSejThHTM0Ymzs4ebiXAl0NZ6oa+fN0H9+Z2jh26BLG5guYjrXQEQ+QFHEjBd3kPZpSuxQygi4Kv/dj3IpT+nuc3tUIhk9OYmho40h5O9riIzw3fcPfJ66gLzFxSNeOsaGVyupTdMizpEaH0QTdiAEnRT/cRxAfDJG1dB3dszAlr2L0PI5Trq+lqK6Bo6Vt7CqopPHD4zR+VENOQRWm0jYO1DZgrDzB+bCP5KUhMoMuxPsOsoNuBCnoIt1/jT2JSUq2YrRuPaS+rhWNroLK6iYM+fXk5FWRs6dK9RWfNql3Jz8/j2ltluSAHU1gCOm+A3F+EEGSnUgLDjLmrrL/ySxVf63RJ/vQ5upJ15VzsLIOQ3G9KsWnl+ko2Guke/oumZERROW9AvPbkRSguOBA8tsRZ21k++wU/7zIZ3+sYx6foCi/gnfe1ZKUX6BK8cXaKrpGRtgfn2SHbEcjO1WY0p00a0OQ/lOk3+0jLzLGwV/DFD72cjbo4ctvzdTUNalS/FfyXfbExkmRHWgCTsQFO5LvGtLcVaQZK4L0GiZOW5A8A2pA7AyNkLk6gbg6jvbRFO+HR9GGbpC3dpuUsJu0gFPd2/9gXivStAVBKcQZqxoKSjik3LzIm64LZEdHyUmMqwGQFHTydsBO2qIbTdCF9GBI/VXp9d6UZFJgyvt/AMFJxnRz6UHmAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Speedster 3.0 icon design (macOS).",
              "title": "Speedster 3.0 icon design (macOS).",
              "src": "/static/f7ba75f9f7b190960ac81936dcd07465/5ece7/speedster-icon.png",
              "srcSet": ["/static/f7ba75f9f7b190960ac81936dcd07465/f2d49/speedster-icon.png 300w", "/static/f7ba75f9f7b190960ac81936dcd07465/ff59c/speedster-icon.png 600w", "/static/f7ba75f9f7b190960ac81936dcd07465/5ece7/speedster-icon.png 1200w", "/static/f7ba75f9f7b190960ac81936dcd07465/0d4f8/speedster-icon.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Speedster 3.0 icon design (macOS).`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "2016",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2016",
        "aria-label": "2016 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2016`}</h3>
    <p>{`Designed and developed an Internet bandwidth testing app for macOS, née Speedster.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/68d6f25d2670d6c65f896eeb7df1e6f6/0d4f8/speedster.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEIklEQVQ4yx3MWWzTBQCA8f+TT5MrtHRdj63r8W/XY+2u3mXd1e4AxzHEDcdSNlAEdOPQwZjsEgcjYxxjgWUxDAIGA4lRE8MjRoKgrNeAMboAC06CooCYGP2MPPwevpdPmPvtGT8//ZO5F//y5MU/zP3xNw9+fcnM4xfc++U5qf/NPSMx/YibiRluxlMkYili8RTxxAwTk/eZuPOQ6NQs8alZhCfP/+LajSgnB/vp6O5l154u+gaG2Lf/INvbO2n7aC8dPf1c+ek21+Ipvp+4y/XoNBPxFNHoND9Ep7k6cZcb0XvEYymEx7+/5PjIKIZMORlyGfPnz0cukyGVSFi4YAHzXk9DrVLx9bffkUg+4HY0RTI288pk4j7JW7MkJh+SvDnNzPVbCPdmnzB49ASZWRp0Oj0Oux2jScTusGMxm1FnZpJrt3Ppi6+4ePYSp0+e5czoOU6Pfc7oyDijw6cZGRrjm4uXuX01ifDjnYf0HDiMSqnEZDSit5rJys1D58hHZxQx6PWvhkeGT9He3kl31yd0f9zHiaERxk+NMzJwnCN9h/hsYJhH15IIsalZ9h8cQq3MIMeei8ZegEWrQaM1YCgoRBQN2Gx2jhw6xtaNW2h7930+aNnMzvfa6N65m75dHXRuaeXC4WGe3kgiXLl8nb3t3agUcqxmHYFcI9XmDLSZajRmK4psPQ6bnbFPh2ioqmVVaSV14RXUBcuoDddRV1rJMneACwNHIT6FsLa+kaWBINosNWatGrNBh95kxpRjIlurI9NgwWw08WHTBlYuDRB+YyOhDYOE1+wg1NhLTW0LwZVtDHb2EztzFsEZ9JDntCORLEauUJKhUCJPT0elzkSpVCKVLEYmk1Hk8VJYvAJHuBl7uAVruAVLRROWynewV0QoC1ZSnF+EoBGV6EUlafPS0GiyyDGKLFq0CHmGEpMoki5bglQqZe2aepzFy7F5KrC4S7C6Apjy3FgLAziK/PidPqq8pQj5hVa0Oi0LJVIWZyiRKFRIFSokcgVShfpVZ2u0bF7fQjDgw+P34Sv24/U6cOTmsKw6THWonHKvlZDLiWAQ9aiztfjMIo0uC40+O5HiQppLimgO5LHebaPc5aKpsZl8Vx7BMhdObwFFDhlBx2vodAvQmbIx6NIIFCxBcAdDZFkL6Ss2cffNHKIN+UxuKiG5KUhsjZVEnZlmfx4GWxEWh0iBMx2X10R5aS4Nq8t4a10DlbW11Dc1Etm0AaG2fj3bWltZUR1iZWU5y0MVBD1uQl4/lSVl1JSH8Ll9aI1mssUscmwqOnv3ceDked7e2kHzji66+ofo7D9G654ehPPjp/jy3Bi9XV3s6+lj9boIVTWr2BjZRmt7N7u37yWyLoJotaFRL8EoygmGSvBX1+Ar8eD1eXD7i3G6nLg8Lv4D2H6Jt35l/RIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Speedster interface design (macOS).",
              "title": "Speedster interface design (macOS).",
              "src": "/static/68d6f25d2670d6c65f896eeb7df1e6f6/5ece7/speedster.png",
              "srcSet": ["/static/68d6f25d2670d6c65f896eeb7df1e6f6/f2d49/speedster.png 300w", "/static/68d6f25d2670d6c65f896eeb7df1e6f6/ff59c/speedster.png 600w", "/static/68d6f25d2670d6c65f896eeb7df1e6f6/5ece7/speedster.png 1200w", "/static/68d6f25d2670d6c65f896eeb7df1e6f6/0d4f8/speedster.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Speedster interface design (macOS).`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3d48b0a47b5f573547dca5209ee11fdd/0d4f8/kromatic-icon.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEIklEQVQ4yzXMf1gMdgDH8W+op8yPzZZn7WnrifUseaTjinLmuq6oRW50dIWN/NMI65lNhsYjrsePjsfl6hH9zpLdSb9UyuhS13LF+ZFSU56H0uZ5PIjSe8+y/fF63p+/PiJHn82Fc+VUVF+n0myjtLmTCy1/csHaQ2lrD2XWbipaH1J1s4crtkc0dDyh6UE/zV1Pae5+x/Kvrqc0dT1FnN6WQMG+AxQezyA3x8QZ0zUyq9vIuHyLk3X3ybvRT4mtj4r2fko7Byi72UvdrV7M7Y9puP94tP+rb3+MyItWkR+7htPx8ej3aNGlnUWbV8OR8ruYm//g75IU3ui2MpS0k2cZ+bS23Ke4pZui+nuYmjow/sdk6RwlilSL+HWlkqwoFfq4zWj3pnKk4Co9liwwfAxxApR24O7CiJeCgdU7uVNmIevqbfSlLaRXWjFUWEebUdmKyA2dT2GYP7nLAjGsjeJA4mHuXM8BowNDOsHILjte/iBncLWGv2QxWBXbqdfoMdfYOF7ewjGTBZ2xidTfGjl6/joidZEf6XIpp0ICOLw0jOwjJxi5qeBtmWCoeDK25kIaB97Q/WqYjsutlGoMGFS5GFPMlDTe42SJBYOpkRPnrqErvILY4jufPX5SUmR+JC5UUpWjhc6pDDcIBrt282AEbM9f0/VqhNoXb8ktvkXyZjNJ263UmDsoKG8g/3wdZ/LKMZwyIUK8v0Qz259N0nnE+gZTlbcDOiYxaBFYHxTSMACPBt/waHCY+r5BbH3tVFXXsm53L6ZL97hYXIIps4CiY+lkHjyOmD4zBOlMBUskgQTNUnI6LYkn7R9xwyw435xAbR90vhyi/cUwQ0BabS+eiQ+Rp8DFMgsXdTrKDyRjTPyJjC3fIyZ7R+AyKxzPOeF4SsJZH78H820ZmWZB0u8u5Hdc4u5zaH32mtS2Llz0vYxLhohcOJd2iuyEBIq2biJn43q0mjUIJ8lKJkpW4OyrxnVhNK7yOPTGfWjbPmRp3ft4VPsx40o8Xpf2MyYnA3GyFtfsfpLzG9imiWVHdCy/RK1l5woNsaFqhKOPCqe5kUzyj8FZsZEPFBvxUv3Iocq9qNvkOFwLQNQtRFSoEUYtHmU1bD1bS0j4BvwWRBIUpGGxUkNwUBTzFqxEOMxcjKNPBBPmR42eTV0cxwTZN7gpv2PD0RRiLu9nSdPPhNYfZFVJFpp9x3DzjWS8RyjO3suZ6vM1LlI1n0jVOM9WIcZOD8B+RhBOkggmy6KZIv+WKbI1OM5dgYPPKjzCtuEbswuJejuuC9Yh3JQINwXjpgczdlow4zyWYO8ZjoPXMuy/CEPYferDGPd52HsGMl4SzkRfFe/NWYaTdyhOkq+wl6oY6xeJnc9yhGcIYz4PRHwmQ7j6I1wD3m33RdhNU2DnLucfySX+XMYpR18AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Kromatic icon design (macOS).",
              "title": "Kromatic icon design (macOS).",
              "src": "/static/3d48b0a47b5f573547dca5209ee11fdd/5ece7/kromatic-icon.png",
              "srcSet": ["/static/3d48b0a47b5f573547dca5209ee11fdd/f2d49/kromatic-icon.png 300w", "/static/3d48b0a47b5f573547dca5209ee11fdd/ff59c/kromatic-icon.png 600w", "/static/3d48b0a47b5f573547dca5209ee11fdd/5ece7/kromatic-icon.png 1200w", "/static/3d48b0a47b5f573547dca5209ee11fdd/0d4f8/kromatic-icon.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Kromatic icon design (macOS).`}</figcaption>{`
  `}</figure></p>
    <p>{`Designed and developed a color picker utility for macOS.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ef92885e91c985179eee221002920507/0d4f8/kromatic-ui.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD3UlEQVQ4y0XP7U9TBxTH8fsfzL2YoGzKU58fbtvb2/be25bbJ9tSKFKkEBWJc+peIDqmmyLqpsyOBcl0c1lMlsxkZsTNIRuP2xQRShWCobDNN/tfvgvlxV58ck5OTk5+R3hdXuTf1ytMPrrPrdERfnl4n/LqPCsLE4yOjTEwVODa519ybvAm7b0X6O3/lMmJh5Rf/s5acZYXz6dZWZxheWGaZ0+mEIpPJ1lbnuFVaYbSwmPWlqcov5zjwQ/f039xmOujX3O1cJsPBgv09F0jf/oKfYNfUBj7huVnU7x6MU/p+QwvlmYrVXgy+zOLf0ywXpqntDTN0tNJJh+Pc+FagaGbY5y5XOB4/1V6+4boPHWZY+ducOL8TdI9Fzh5fpjxn8YpLs5Uji38+RvCdrq14hxrK3OMdGcZyqp0+3x0iE5yTpFEg4l20Uveq5AyWslY7BUpowV9v4GkwcJHAxf5depx5XVh4tGPrBZn+e7eXeZjYVZVD0uql2VFqlhRJVZDPtZ1hZLqpahKFFUvK5qXUlCmpHgYPnmG/hu3GR9/gHDn7reM3rnLyMgIi5kDbOkq5VhoR0RjMxVlqy3NZjbNViZBORqs2KjUEGVdYfjd97l06x4fD99G6L/4GcfPfsKlK9d51pxgoynAekTjVURjvUlhIx3n797D/HUkz1bnwcpsXVcrtnc2dJVuf5xQ+2mGCl8h1Es56qQODO4MUyGNfyIqGxGNckRlMxpkcztxyL8jHNiZbafbPhgOsKUrvBdpwRLqQs+cQOg7O0ju2ACS3kWrQ6TLYSVnNdNhNdNmNOwwGWkzGXYYDWQNjXTabfT6ZbqdDtJ6mvb8KVpzJxA+HLjE2YEh8kdOYRbd1JlNNJit7Gs0smt3NW9W7dhV9X//xu4q9htNhBMHsHk8OFwq0dghkskuBJ+WpSmWJ3agA6vVTm1dIyajmfr9+6jZs5u9VTXsqanhnaoaaqqr2VO9l+q3qmmoq8Mv+7CYjHg9CsFQC4qWQXB4U4hymnpLmHRMpjdmJh1wIOsdSPJBWg45yZvsmHJWoolmsmmNjs44eiSO7A/Q1dWJ2+Ujl82Ta+1EMIkJai06BmuYgN+P4rKjSB5Ej4bVFsCvOGlyujHKdsw2CZvdgdPlwmx1YbGKuCUFi82PGmwmlWxH8AQymJxxDPYItSaNvQ0+7O4wNqeGJIeJ6WmSqRb8chS7qGG0KjSY/BgsfhrNPt6ulxA9UXS9lbDeiiD60khqBosrgUtO4vDEMTqjSEqGo91H6TncgxLMYHPHEOUILl8Ylxyv7MmBFJIvSTDUTFvLIaLRNv4Daqh1XigYx2cAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Kromatic interface design (macOS).",
              "title": "Kromatic interface design (macOS).",
              "src": "/static/ef92885e91c985179eee221002920507/5ece7/kromatic-ui.png",
              "srcSet": ["/static/ef92885e91c985179eee221002920507/f2d49/kromatic-ui.png 300w", "/static/ef92885e91c985179eee221002920507/ff59c/kromatic-ui.png 600w", "/static/ef92885e91c985179eee221002920507/5ece7/kromatic-ui.png 1200w", "/static/ef92885e91c985179eee221002920507/0d4f8/kromatic-ui.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Kromatic interface design (macOS).`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "2015",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2015",
        "aria-label": "2015 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2015`}</h3>
    <p>{`In summer 2015 I had the opportunity to work on game design at thoughtbot Stockholm.
The result was a mobile game that I designed and made assets for.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/97430cbcd0d20f001e30f2868b2694c7/0d4f8/rototo.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEAUlEQVQ4yzXPyVPTdwBA8W8Pth2pS1myEZKQhUASIkvAqkOI+RFCUHBspQqUsbKI7BIgxarZwKVWrAsKCoqoWGtBxMpmEUGc0VN76NGLh07/gp77OkJ7eNfPzBPO+HwKEvLxylxI8dsIWK7Tv/UPotkrRLJf0bllmaP2JQ7bFqix/UqFZZZ9aU8pMc2wwzaE2nGUhC1+YjPbiLG1IrwyJz5ZPrtlOylO2E5n2gB9Ob8TzXzJmW0rdGctr6JN9kXq0xeoss6x3zLN3pRZ8qzDqHe0Ifc2E5t+lA3mZsSX8p1Uyt1UKiQq5E7azf0EM94QzVwinPuCTvsi7fZFWu3PaUhf4JBtnorUacpMM+Rbh0nc3kxiWT2bcv18WBxAVMklDsolahUSdXInrcaLdFlX6M6cJ5z3jDbzAk3WeZptz6i1zlNjmaMy5xmVW1+QZ7nOZrefdV+dRHRGEcdOIfxKN90qN10KNwFFHq36PhrTFmkyT9NknKXDMU2rfZYGyxw1lqdUW6Ypz5jhkO05jvxbiGAIEe5FBHoRnT2IbqWb40o3IZVEr8pJS/I5vjbOUWuc5KBhiraMX2i0PqHSMEl16hMqzY+pSJ2iyjBDtncUEYwgAj2Irl5EVwRxTu3mskbisqaAK0lOGjSn2Kedoip5nEOGScq14xy0PaIh6wnlhgkOpDziQOokZclT5HhGEccj/2E9iM4w4lKSmwGtxJC2gDs6F01JUXyJD9mnGeNz3X32qMYIuOfo8M1Smvgj5aYJ9ph+Zn/yYxzSCOJYdA3sCCP8QcRtXQEP9R7GDYVMm9zUJwZxKu/ik99il3KUvdo7lGrvruJlugeU6O5TanzAF9oJ7K6ba+D/2Pvlcb2HCb2Hp/pCFo0SdapvyYkdwWO+SkHyIJJiGJ9qhBL1XYo1o5To7lFiGGO3+icyXEOIQBDhDyHaQ4i2KGJK72HJ4GXJ6OO1yUOd8hssMddwOy6wM6UfZ9wgLsV1XMobFKlH8GlG8ehGKVU/wO4cRAROrIFNEURbD2LZ4OWlsYg3yV7emgupk/vRbOgjff157Bt/ICfuMtsSriIph3CpbiAl3qRQc5ti1RiWvKuIjhCiJYxoDyIaw4glYxG/Gbz86SjjL8suauJbSVh/hrSNp7FsOseWTy+QFXeRHFk/O+SDeDXDVNhv41HcwZp3bW21IcLH33fwQcsJxCtjEW9NPt7ZSvjbVkRt/BFiPgqiiwmj++QUKZu+wxbbR1b8JXJl/WxVDFCgHcarvIfps35ESxBxOIQ4HEQciSJer4LFvDP5+CfdS3VcLevWdZO0PkhSTAT9htOYNp8lNfY8tviLOOTXyFYO4JANocu9gqg/iagLIarfF+ZfpLZglQeOEbsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Rototo game concept art (iOS and Android).",
              "title": "Rototo game concept art (iOS and Android).",
              "src": "/static/97430cbcd0d20f001e30f2868b2694c7/5ece7/rototo.png",
              "srcSet": ["/static/97430cbcd0d20f001e30f2868b2694c7/f2d49/rototo.png 300w", "/static/97430cbcd0d20f001e30f2868b2694c7/ff59c/rototo.png 600w", "/static/97430cbcd0d20f001e30f2868b2694c7/5ece7/rototo.png 1200w", "/static/97430cbcd0d20f001e30f2868b2694c7/0d4f8/rototo.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Rototo game concept art (iOS and Android).`}</figcaption>{`
  `}</figure>{`
`}<figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/557d7f1fe844a3d9350d4eb4c7cd09d9/0d4f8/rototo-game.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+klEQVQ4y31T2W7iQBD0r+bfeOaFfUViE1gHkZgrHLY57JkgsLjvw0BF1WgQcpIdqeWZnu7qqp62pdQAQfCBfP4JnvcHWkcIwwBhGKLVaqHf70MpJWfV64oFQQDXa0NrDc/zUKtWZU+/5Xk+HKcEz8tCayZruSRAu31LIqD+/ETlvYK3t7IkvuaKEtt32/DLDpQBbDQasO1XlEoVOE4Z9Xod9DmOI+b7PjqdjjCtugrFD3Vj7GpopREGARTZq5vfAoDFYoEoGmE8HmM+n8uXDLvdroBRerVSgW3/w8tLHs/Pf2EXbSlYq9WkKNmxqABer1ecz2dcLhf8tOg/xTHW6zUmkwmiKLr3zHVdFAoFZDIZpFKpG2AcxzgcDgL8G+hviznH4xG73Q6r1QoWHblcTioaNmRA6fxuNhthdjqdJPlREYkkl0Vm6XRapJjg6XQqIByJZrOJbDYr4MvlEr1eT4pQ5mAwkBz6yVIAuSFVGsHIxOxpLDgajYQ5i/KxyJ7ASQI0y/SPxkVAJuz3+2+9YgKlPi6eGUsjUyuZ9NPZsN1ut/e+mTsS4JjdJZtgE0CmHAe+2iP7ZLHHQmbkuP8GyEsOKPsyHA7lr+EfwASyoDQ+FNuSVCWAj06CzWYzeUWy45kglGpGikYFRmKSkJXsFcHMA/2vv8k8M4dfoStzKMc8wV0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Early game concepts.",
              "title": "Early game concepts.",
              "src": "/static/557d7f1fe844a3d9350d4eb4c7cd09d9/5ece7/rototo-game.png",
              "srcSet": ["/static/557d7f1fe844a3d9350d4eb4c7cd09d9/f2d49/rototo-game.png 300w", "/static/557d7f1fe844a3d9350d4eb4c7cd09d9/ff59c/rototo-game.png 600w", "/static/557d7f1fe844a3d9350d4eb4c7cd09d9/5ece7/rototo-game.png 1200w", "/static/557d7f1fe844a3d9350d4eb4c7cd09d9/0d4f8/rototo-game.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Early game concepts.`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "2014",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2014",
        "aria-label": "2014 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2014`}</h3>
    <p>{`I designed the UI of Spogo, an iPhone app for sport bets (discontinued).`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3176542f6c89fcfb2026c6c1d0723fca/0d4f8/spogo.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAECElEQVQ4yyWSaVCUBRzG3y996UvT9CGbNMpAA+lADYXUyhzNIoRRPCZAQ45tzIOEKfFGFryP1MFC2h01BlGSQ0RiQEQJBWR3YRHc5RCJTXb33ZuXZQ9+jdt/5vn2zPM8v5m/QO5LcOxl+OVVps5Mw3/+LSgKhithOBTzaMhbQFZCOJV7PoT7X/HTpmhmL5ex9nQzRXdGEDWnaVSs4W5dHZLXi+A//AqqXTNo2DkTCoOgOASfMpyTsgVERcwi+N1gpocvJjgmi7vKJOzVy9m8NZPqOx2Aj3GXC0t3LlcKU2lXP0FYu/R9wkJDeePNII4kzoI/QqH0YzZ8GcHsqFgWpJ9lUcEDsm8YaGhV43mQjurSCg7K5VgkD24vmA39GB5upfjX3QhBb7/DBxHzmReTykepF1AciIeKCIaKFrIyNp6C8h56n0+Ab5wXN9jbik+dRvm5OMquV4Ik4lLdwzrykGfdOQiRifuI3lNLpLyT+DNqFLUqdJfioekLbskj2ZX9YyBIkiRsDgcTHhjS3sSv28JZ+TrqlBfBN4HL5abt8d8Iy/LbSFYOUdE+im10EJxm1Op2BktWwqMETmUuRH7kNH7AYnMw4ZaY9MBITwnSQCb796ah7x8G/GSeKEW4equRYb0K91MN1n41JrMJaQq6Wqsx1cRCdwo7NkZTeq3q/6XjEv4pLwargX8HLjCg3ok8L4fS0muEhsxE+EGWxjNdFwbjGMM2N0aTEdegGqdrkkeNF/G0JSJ1ykhK+JxOlRY/kzwV+5C8joB3qL+IypqDhISEkLItFeHrJXPIP3ocXVcLI/rH2HpbcRj/wWx3YhAlOv46AkPb6WvIYP26WHpHtEieF+gekJwM9XXSrCmjpP4yyju1CIO/LyZqbhhVFVXglRgTbbgmJrHYHTy3OXn6zICmcR+4DlF1OYmM9IwAOuOmgBobGlm9YQ3KptucrG1GoPkb6k8sYX7kQnr7ngR+a9howe5wIIoitnE3Or0OXfte4DwFeQkUHDzAbZWTPEUTkRHhBM9+j0NlteSUtyN4G+KgM5EzWVEsWxGDSRQD68yiiMvlxGSzY3R46NK0oNfmYzQp2bQ5jpkr9jAjbBFB06fx/eFz5NxQkarUIEzdX4f/QRL0yJCtn8vGFBk+7yTPrQ5MNicOpytQYrNL3Gup4d6j36hrL+PTpdFMf/01vs0+QE5FFymKLuIL9Qi0JePvTIXHW3B3b2f5Z3M4fPQkkxBAt1itWCxWbHY7Y2Mmrt+8SklLDceu/8nG/afYWdrBdwotq87rWXx8BGFKlQY9W/D17oDR3fS3bGP+vDAqKquZcLsxi5aADAYD+oF+4latIjn7Z47Wa8gq15JcrCXm3ACfHBslNNfMf1Oyib6ywsOkAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Spogo UI design.",
              "title": "Spogo UI design.",
              "src": "/static/3176542f6c89fcfb2026c6c1d0723fca/5ece7/spogo.png",
              "srcSet": ["/static/3176542f6c89fcfb2026c6c1d0723fca/f2d49/spogo.png 300w", "/static/3176542f6c89fcfb2026c6c1d0723fca/ff59c/spogo.png 600w", "/static/3176542f6c89fcfb2026c6c1d0723fca/5ece7/spogo.png 1200w", "/static/3176542f6c89fcfb2026c6c1d0723fca/0d4f8/spogo.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Spogo UI design.`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "2012",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2012",
        "aria-label": "2012 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2012`}</h3>
    <p>{`Icon commissioned by `}<a parentName="p" {...{
        "href": "https://alexknight.net"
      }}>{`Alex Knight`}</a>{`.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5ebc78d9f17940a17ba9dd78ce370739/0d4f8/waffle-sauce.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxUlEQVQ4y1WTy2sUSRzH52/wsHjwKnjxpBf/Ak+C60VR8aCwLLsoRgUf+EBEBIMHV3c3rINEFN+KEV+IEs0hQRMQJBhBjcm8e2Z6pnumH9XdVf1ZuronM3P4UXR39ac+VfX75oLOT0JnicgpELklIreC9Kq6lFdD+kkZSL+elaFLf8vmRV6FyC0TOUVyQXeJsAfzMphfRWUQ5TeGSzT1mH4z0gUHoLnQWdYw2TPza3pi/+cmsTCJQ4s4aKfveqXBydwM6lbIJVuViW5mN2QmWqioQxzaKHsJ5VZRUTd9FqZeLDU39K6klwATO69/bnpFbWRro9hcwJ35j9L4H7Rf/0VUmEY6FeLeQsLMBFLL3MrZJVsNTFQCScCVOezJUco39tN9f5XOu0u0Jo5TunkQ6+3fhItTyPbP1FJDjR6wnJolY30ef/4JtfuHqD84hT83TuvZSWr5vRQu76Q5tgVjbCvNu79TuLaP1ouLRIuTKKeCFI2+oQpNxPIU7Ue/4U2NYk/9Q+vVecq3D1M6u4nasV8ojKxi+chqvo+soT66nvazUwQLLxGfHxGU5lBhe8DQNwitRaqPT1DNb6P78ihiNo89M07j3gG+H1tH8cxamlc2Uj23DvvmDsIvEzifHmDPvyK0i/rWpVfrbbmCEnWk18CafUg5vwfj2q+4r08jPt2i8/EO3Q+36d7fhXV1A503F7AX3uCWP6MCizixyy6mf4a6ZeqAh2wvYU2OYVzfjTm+HX/6CuHX59hP/6RxazPO18dEogXKJU5ay2+mwNRwICE6TgZxZAECaf7AmcnjTv9LWJwlqM0hChOIb9eR1gJx2MmavDF4y8X0hrVhbShusXSIY0+3kpJdVCxQMn3WPZrYieZwH6Y5HkxKAu3HTjd5YKXQJB2ilcHMlfipzC5hZFnuWVaGTVdya6bgbOy9lwOwNMtl/gcFxUpENC7rHwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "WaffleSauce icon design.",
              "title": "WaffleSauce icon design.",
              "src": "/static/5ebc78d9f17940a17ba9dd78ce370739/5ece7/waffle-sauce.png",
              "srcSet": ["/static/5ebc78d9f17940a17ba9dd78ce370739/f2d49/waffle-sauce.png 300w", "/static/5ebc78d9f17940a17ba9dd78ce370739/ff59c/waffle-sauce.png 600w", "/static/5ebc78d9f17940a17ba9dd78ce370739/5ece7/waffle-sauce.png 1200w", "/static/5ebc78d9f17940a17ba9dd78ce370739/0d4f8/waffle-sauce.png 1600w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`WaffleSauce icon design.`}</figcaption>{`
  `}</figure></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      